//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios'
export default {
  data(){
	return {
		list:[],
		total:0,//总条数
		currentPage: 1,
		pageSize: 10,
	}
  },
  mounted(){
	this.getList(true)
  },
  methods:{
		getList(clear){
			axios.get('https://milai.sdmlswkj.com/api/62b91666b0c20',{params:{p:this.currentPage,z:this.pageSize}}).then(res => {
				if(clear){
					this.list = []
				}
				res.data.data.list.forEach(item => {
					this.list.push(item)
				})
				this.total = Number(res.data.data.count)
			}).catch(err => {
				console.log(err)
			})
		},
		handleSizeChange(val) {
			this.pageSize = val
			this.getList(true)
		},
		handleCurrentChange(val) {
			this.currentPage = val
			this.getList(true)
		},
		detailClick(id){
			this.$router.push({
				path: 'index-ordedr-list',
				name:'index-ordedr-list',
				params: {
					orderId:id,
				}
			})
		},
		getIndex(){
			this.$router.push('sing-out')
		},
	}
}
 